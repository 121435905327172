* {
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  @media #{$mxw-sm} {
    max-width: 575px;
  }
}

.heading,
.subheading {
  line-height: 1.2;
  margin-bottom: 15px;
}
.heading {
  width: 100%;
  font-family: $font-heading;
  font-size: 38px;
  @media #{$mxw-md} {
    font-size: 28px;
  }
}
.heading-caps {
  font-family: $font-swiss-light;
  font-size: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.heading-italic {
  font-family: $font-aldine-italic;
  font-size: 24px;
}
.subheading {
  font-family: $font-subheading;
  font-size: 32px;
  @media #{$mxw-md} {
    font-size: 24px;
  }
}
.subheading-2 {
  font-family: $font-subheading;
  font-size: 26px;
  @media #{$mxw-md} {
    font-size: 18px;
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%
// LINK STYLES
.inline {
  font-family: $font-main-bold;
  color: $bonvoy;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.link-1 {
  font-family: $font-main-bold;
  color: $bonvoy;
  @include transition;
  &:hover {
    color: $black;
    text-decoration: underline;
    sup {
      color: $black;
    }
  }
  &.underline {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
.link-2 {
  color: $black;
  &:hover {
    text-decoration: underline;
  }
}
.link-3 {
  color: $bonvoy;
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.link-arrow {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  font-family: $font-swiss-light;
  font-size: 14px;
  color: $black;
  text-transform: uppercase;
  letter-spacing: 0.93px;
  text-align: left;
  @include transition;
  &:before {
    content: url('#{$imgPath}icon_double-caret-right.png');
    position: absolute;
    top: -2px;
    left: -23px;
    transform: scale(0.5);
  }
  &:hover,
  &:focus {
    font-weight: bold;
  }
}
.link-arrow-black {
  @extend .link-arrow;
  &:before {
    content: url('#{$imgPath}icon_double-caret-right_black.png');
  }
}
// [END] LINK STYLES
// %%%%%%%%%%%%%%%%%%%%%%%
// BUTTON STYLES
[class*='btn-'] {
  min-width: 190px;
}
.btn-1,
.btn-primary {
  display: inline-block;
  background-color: $black;
  color: #fff;
  font-family: $font-btn;
  font-size: 16px;
  border-radius: 22px;
  padding: 11px 42px;
  text-align: center;
  height: auto;
  transition: 0.1s background-color ease-in-out;
  &:hover {
    background-color: lighten($black, 20%);
    text-decoration: none;
  }
  &[disabled='true'],
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
    &:hover {
      background-color: $black;
    }
  }
}
.btn-2,
.btn-secondary,
.btn-primary-inverse {
  @extend .btn-1;
  background-color: #fff;
  color: $black;
  &:hover {
    background-color: darken(#fff, 25%);
  }
}
.btn-2-bd {
  @extend .btn-2;
  border: $bd-black;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}
.btn-sm {
  padding: 2px 14px 3px;
}
// [END] BUTTON STYLES
// %%%%%%%%%%%%%%%%%%%%%%%

.flip-container {
  perspective: 1000;
  background: transparent;
}
/* flip the picture when hover */
.flip-container:hover .flipper,
.flip-container.hover .flipper {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
/* flip speed */
.flipper {
  transition: 0.2s;
  transform-style: preserve-3d;
  position: relative;
}
/* hide back  during flip */
.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
/* front placed above back */
.front {
  z-index: 2;
}
/* back, initially hidden */
.back {
  transform: rotateY(180deg);
}

.dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  img.arrow {
    width: 14px;
    transition: 0.1s transform ease-in-out;
  }
  &.show {
    img.arrow {
      transform: rotate(180deg);
    }
  }
  &.disabled {
    background-color: #f6f6f6;
    cursor: not-allowed;
    .dropdown-toggle {
      color: $gray-60;
      pointer-events: none;
    }
    .arrow {
      opacity: 0.4;
    }
  }
}
.dropdown-toggle {
  height: 44px;
  border: 1px solid $gray-10;
  box-shadow: $box-shadow-2;
  padding: 0 12px;
  border-radius: 5px;
  &:after {
    display: none;
  }
}
.dropdown-menu {
  padding: 4px 0;
  max-height: 350px;
  overflow-y: scroll;
  button {
    font-size: 14px;
    text-align: left;
    padding: 3px 12px;
    * {
      pointer-events: none;
    }
    &:hover {
      background-color: $gray-5;
    }
  }
}

.errors {
  position: relative;
  background-color: $color-error-lt;
  padding: 20px 50px 20px 20px;
  max-width: 380px;
  @media #{$mxw-md} {
    max-width: unset;
  }
  p,
  li {
    font-family: $font-main-semibold;
    font-size: 14px;
    color: $color-error;
    padding-bottom: 10px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
  .icon-error {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.modal {
  .modal-dialog {
    @media #{$mnw-sm} {
      width: 1028px;
      max-width: 95vw;
    }
    @media #{$mxw-sm} {
      padding-bottom: 40px;
    }
  }
  .modal-content {
    border-radius: 0;
  }
  .modal-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
  }
  .modal-body {
    padding: 30px 30px 0;
    @media #{$mxw-sm} {
      padding: 0;
    }
  }
  .close {
    position: absolute;
    top: 17px;
    right: 25px;
    font-size: 26px;
    opacity: 1;
    z-index: 9;
    &:hover {
      opacity: 0.75;
    }
  }
}

.loading-spinner {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.8);
  &.show {
    display: block;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  background-color: $bonvoy-white;
  padding: 15px 20px 20px;
  // Bootstrap reset
  border: none;
  border-radius: 0;
  .img-wrap {
    height: 37px;
  }
  img {
    margin-bottom: 10px;
  }
}

.box {
  border: 2px solid $coral-lt;
  padding: 33px 160px 36px;
  max-width: 830px;
  margin: 0 auto;
  @media #{$mxw-lg} {
    padding: 33px 100px 36px;
  }
  @media #{$mxw-md} {
    padding: 33px 50px 36px;
  }
  @media #{$mxw-sm} {
    padding: 25px 25px 30px;
  }
}

.banner {
  background-color: $coral-lt;
  margin: 30px auto -30px;
  padding: 20px 55px;
  border-radius: 16px;
  @media #{$mxw-sm} {
    margin: 20px auto 30px;
  }
}

p {
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
button {
  color: $black;
}
a {
  cursor: pointer;
  text-decoration: none;
  @include transition;
  &:hover {
    text-decoration: none;
  }
}
img {
  width: 100%;
}
ul.roman {
  list-style: lower-roman;
}
ul.lower-alpha {
  list-style: lower-alpha;
}
ul.upper-alpha {
  list-style: upper-alpha;
}
ul.list-style-none {
  list-style: none;
}
ul ul {
  margin-top: 15px;
}
ul,
ol {
  margin-left: 40px;
  ul,
  ol {
    margin-left: 16px;
  }
}
li {
  padding-bottom: 6px;
  &:last-of-type { margin-bottom: 0; }
}
hr {
  border-top: 1px solid #979797;
}
button {
  background-color: transparent;
}
sup {
  top: -0.9em;
  font-size: 50%;
}

.input-wrap {
  &.text {
    display: flex;
    flex-direction: column;
  }
  &.radio {
    display: flex;
    align-items: center;
    input {
      width: $radio-btn-size;
      flex: 0 0 $radio-btn-size;
    }
  }
  label {
    margin: 0;
  }
  &.checkbox {
    display: flex;
    align-items: flex-start;
    label {
      margin-top: 5px;
    }
  }
}

input[type='text'],
input[type='email'],
input[type='tel'],
select {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid $black;
  border-radius: $input-bd-rad;
  height: 44px;
}
select {
  background: url('#{$imgPath}icon_caret-down.png') no-repeat 95% 50%;
  background-color: #fff;
  background-size: 8px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
input[type='checkbox'] {
  margin: 2px 8px 0 0;
}
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid $black;
  border-radius: $input-bd-rad;
}

.error-msg {
  display: none;
  position: relative;
  font-size: 14px;
  line-height: 1.15;
  font-family: $font-main-semibold;
  color: $color-error;
  background-color: $color-error-lt;
  padding: 12px 10px 16px;
  border-radius: 4px;
  &.show {
    display: flex;
  }
  ul {
    list-style: none;
    margin-left: 0;
  }
  li {
    padding-bottom: 12px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
}
.error {
  position: relative;
  input,
  select {
    border-color: $color-error;
    &:focus,
    &:focus-visible {
      outline: 1px solid $color-error;
    }
  }
}
.icon-error {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

// CUSTOM RADIO BUTTONS
input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0 10px 0 0;
  font: inherit;
  color: $radio-btn-color;
  width: $radio-btn-size;
  height: $radio-btn-size;
  flex: 1 0 auto;
  border: 1px solid $radio-btn-color;
  border-radius: 50%;
  display: grid;
  place-content: center;
  &:before {
    content: '';
    width: $radio-btn-size;
    height: $radio-btn-size;
    border-radius: 50%;
    transform: scale(0);
    box-shadow: inset 1em 1em $radio-btn-color;
    background-color: CanvasText; // Windows High Contrast Mode
  }
  &:checked:before {
    transform: scale(1);
  }
}
// END CUSTOM RADIO BUTTONS

// CUSTOM CHECKBOX
 .input-wrap.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.input-wrap.checkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid $black;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.input-wrap.checkbox input:checked ~ .checkmark {
  background-color: $black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.input-wrap.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.input-wrap.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
// END CUSTOM CHECKBOX

[class*='icon-'] {
  position: relative;
  &:before {
    font-family: 'Icons';
    position: absolute;
    left: 0;
    top: 0;
  }
}
.icon-error:before {
  content: '\e923';
  color: $color-error;
}

// %%%%%%%%%%%%%%%%%%%%%%%
// RANGE STYLING
input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

// %%%%%%%%%%%%%%%%%%%%%%%
// RANGE THUMB STYLING
$range-thumb-border: 2px solid $black;
$range-thumb-sz: 12px;
$range-thumb-bd-rad: 50%;
/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: $range-thumb-border;
  height: $range-thumb-sz + 4;
  width: $range-thumb-sz + 4;
  border-radius: $range-thumb-bd-rad;
  background: #ffffff;
  cursor: pointer;
  margin-top: -6px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  border: $range-thumb-border;
  height: $range-thumb-sz;
  width: $range-thumb-sz;
  border-radius: $range-thumb-bd-rad;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  border: $range-thumb-border;
  height: $range-thumb-sz;
  width: $range-thumb-sz;
  border-radius: $range-thumb-bd-rad;
  background: #ffffff;
  cursor: pointer;
}

// %%%%%%%%%%%%%%%%%%%%%%%
// RANGE TRACK STYLING
$range-track-h: 4px;
$range-track-color: $coral;
$range-track-bd-rad: 3px;
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: $range-track-h;
  cursor: pointer;
  background: $range-track-color;
  border-radius: $range-track-bd-rad;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: $range-track-color;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: $range-track-h;
  cursor: pointer;
  background: $range-track-color;
  border-radius: $range-track-bd-rad;
}
input[type=range]::-ms-track {
  width: 100%;
  height: $range-track-h;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: $range-track-color;
  border-radius: $range-track-bd-rad;
}
input[type=range]:focus::-ms-fill-lower {
  background: $range-track-color;
}
input[type=range]::-ms-fill-upper {
  background: $range-track-color;
  border-radius: $range-track-bd-rad;
}
input[type=range]:focus::-ms-fill-upper {
  background: $range-track-color;
}
