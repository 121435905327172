@import 'fonts';
@import 'variables';
@import 'mixins';

// Vendor
@import '~bootstrap/scss/bootstrap';

html,
body {
  overflow-x: hidden;
}

#app {
  font-family: $font-main;
  font-size: 16px;
  line-height: 1.375;
  @import 'utilities';
  @import 'base';
  @import 'page';
}
