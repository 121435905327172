.number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border: 4px solid $black;
  border-radius: 50%;
  padding: 10px;
  font-family: $font-swiss-heavy;
  font-size: 20px;
}

hr {
  max-width: 1114px;
  margin: 0 auto;
  padding: 0 20px;
  @media #{$mxw-xl} {
    max-width: 80%;
  }
}

.anchor-link {
  position: relative;
  bottom: 124px;
  @media #{$mxw-md} {
    bottom: 44px;
  }
}

.sticky-link {
  position: fixed;
  bottom: 50px;
  z-index: 9999999999;
  .desktop {
    padding: 30px 50px 30px 35px;
    background-color: rgba(#000, 0.8);
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
  }
  img.qr-code {
    width: 95px;
  }
  img.arrow-sticky-link {
    width: 52px;
  }
}

.hero {
  overflow: hidden;
  .inner-wrap {
    position: absolute;
    height: 100%;
  }
  .img-wrap,
  .img-wrap img {
    height: 100%;
  }
  .img-wrap img {
    object-fit: cover;
  }
  .hero-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 645px;
    height: 645px;
    background-color: rgba(#fff, 0.95);
    border-radius: 50%;
    z-index: 9;
    img.logo {
      width: 410px;
    }
    .heading {
      font-size: 50px;
    }
    @media #{$mxw-xl} {
      width: 500px;
      height: 500px;
    }
    @media #{$mxw-lg} {
      width: 400px;
      height: 400px;
      img.logo {
        width: 300px;
      }
      .heading {
        font-size: 38px;
      }
    }
    @media #{$mxw-sm} {
      width: 300px;
      height: 300px;
      img.logo {
        width: 216px;
      }
      .heading {
        font-size: 26px;
      }
    }
    @media #{$mxw-xs} {
      width: 235px;
      height: 235px;
      img.logo {
        width: 160px;
      }
    }
  } // .hero-circle
} // .hero

.section-1 {
  .modules {
    max-width: 1120px;
    margin: 0 auto;
    @include flex-grid(3, 40px);
    @media #{$mxw-md} {
      @include flex-grid(1, 0px);
      max-width: 500px;
      margin: 0 auto;
      .module {
        margin-bottom: 26px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  #link-btn {
    &.fixed {
      position: fixed;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99999999999;
      > * {
        border: 9px solid #fff;
        border-radius: 29px;
      }
    }
  }
}

.section-2 {
  $circle-d: 180px;
  $circle-outline-d: 230px;
  $top-offset: ($circle-outline-d - $circle-d) / 2;
  $caption-gutter-x: 5%;
  $animation-duration: 1s;
  .module {
    min-height: $circle-outline-d;
    margin-bottom: 50px;
    &:last-of-type {
      margin-bottom: 0;
    }
    @media #{$mxw-md} {
      min-height: $circle-outline-d - 40;
    }
  }
  .logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: $top-offset;
    left: 50%;
    transform: translateX(-50%);
    width: $circle-d;
    height: $circle-d;
    background-color: #fff;
    padding: 20px;
    border-radius: 50%;
    z-index: 3;
    .swiper-slide {
      background-color: #fff;
    }
    .moxy {
      margin-top: 10px;
    }
    .w-hotels {
      width: 75px;
    }
    @media #{$mxw-lg} {
      width: $circle-d - 40;
      height: $circle-d - 40;
    }
  }
  .logo-outline {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: $circle-outline-d;
    height: $circle-outline-d;
    background-color: $black-2;
    border-radius: 50%;
    z-index: 1;
    @media #{$mxw-lg} {
      width: $circle-outline-d - 40;
      height: $circle-outline-d - 40;
    }
  }
  .wrap {
    display: flex;
    align-items: center;
    .img-wrap {
      flex: 0 0 52%;
      position: relative;
      padding-top: $top-offset;
      z-index: 2;
      .caption {
        position: absolute;
        bottom: 10px;
        color: #fff;
        font-family: $font-swiss-regular;
        font-size: 14px;
        line-height: 1.1;
        left: $caption-gutter-x;
        z-index: 9;
      }
    }
    .img-border-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100px;
      background-color: $black-2;
      z-index: 0;
    }
    .copy-wrap {
      max-width: 345px;
      margin-left: 140px;
      padding-right: 20px;
      p {
        font-size: 18px;
      }
      @media #{$mxw-lg} {
        margin-left: 110px;
        .subheading-2 {
          font-size: 22px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .module-2 {
    .wrap {
      .img-wrap .caption {
        left: unset;
        right: $caption-gutter-x;
      }
    }
    .img-border-top {
      left: unset;
      right: 0;
    }
    .copy-wrap {
      margin-left: 0;
      margin-right: 140px;
      text-align: right;
      padding-left: 20px;
      @media #{$mxw-lg} {
        margin-right: 90px;
      }
    }
    .img-bg {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 122px;
      border-bottom-left-radius: 122px;
    }
  }
  @media #{$mxw-md} {
    .wrap .img-wrap {
      padding-top: 18px;
    }
    .logo-wrap {
      transform: none;
      top: 18px;
    }
    .logo-outline {
      transform: none;
      width: 176px;
      height: 176px;
    }
    .module-2 .logo-wrap,
    .module-2 .logo-outline {
      left: unset;
    }
    .swiper-slide .img {
      width: 100%;
      height: 210px;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      border-top-right-radius: 122px;
      border-bottom-right-radius: 122px;
    }
    .module-2 .swiper-slide .img {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 122px;
      border-bottom-left-radius: 122px;
    }
    .copy-wrap,
    .module-2 .copy-wrap {
      text-align: center;
      max-width: 500px;
      margin: 40px auto 0;
      padding: 0 30px;
    }
    @include position-img-logo-modules(85%, 80%, 70%, 68%);
  }
  @media (max-width: 675px) {
    .wrap .img-border-top {
      width: 82%;
    }
  }
  @media (max-width: 600px) {
    @include position-img-logo-modules(80%, 76%, 63%, 60%);
  }
  @media #{$mxw-sm} {
    .swiper-slide .img {
      height: 185px;
    }
  }
  @media (max-width: 510px) {
    @include position-img-logo-modules(75%, 78%, 63%, 59%);
  }
  @media (max-width: 460px) {
    @include position-img-logo-modules(75%, 72%, 55%, 50%);
    .swiper-slide .img {
      background-position: 85% 50%;
    }
    .module-1 .swiper-slide-2 .img {
      background-position: 35% 50%;
    }
    .module-2 .swiper-slide .img {
      background-position: 50% 50%;
    }
    .wrap .img-wrap .caption {
      max-width: 70%;
      font-size: 12px;
    }
    .module-2 .wrap .img-wrap .caption {
      text-align: right;
    }
  }
  @media (max-width: 360px) {
    @include position-img-logo-modules(70%, 71%, 52%, 47%);
  }
} // .section-2

.section-3 {
  .grid {
    margin-left: 70px;
    @media #{$mxw-lg} {
      margin-left: 20px;
    }
    @media #{$mxw-sm} {
      margin-left: 0;
    }
  }
  .grid-row {
    display: grid;
    grid-template-columns: 114px 35px 114px 86px 102px auto;
    grid-template-rows: 122px;
    &.header {
      grid-template-rows: auto;
      font-family: $font-aldine-light;
    }
    > * {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    * {
      text-align: center;
    }
    .heading-1,
    .value-1 {
      grid-column: 1 / 2;
    }
    .heading-2,
    .value-2 {
      grid-column: 3 / 4;
    }
    .heading-3,
    .img-wrap {
      grid-column: 5 / 6;
    }
    .body {
      grid-column: 6 / 7;
      padding-left: 55px;
      > * {
        text-align: left;
      }
    }
    [class*='heading'] {
      margin-bottom: 17px;
      line-height: 1.2;
    }
    [class*='value'] > * {
      background-color: $black;
      color: #fff;
      font-family: $font-swiss-medium;
      width: 100%;
      padding: 5px 20px 6px;
      border-radius: 22px;
    }
    .value-1,
    .value-2,
    .img-wrap,
    .body {
      border-top: 1px solid $black;
    }
    @media #{$mxw-md} {
      grid-template-columns: 114px 35px 114px 35px 102px auto;
      [class*='heading'],
      .body {
        text-align: left;
        justify-content: flex-start;
      }
      .img-wrap {
        display: none;
      }
      .body {
        grid-column: 5 / 7;
        padding-left: 0;
      }
    }
    @media #{$mxw-sm} {
      grid-template-columns: 100px 25px 100px 15px 100px auto;
      grid-template-rows: auto;
      [class*='heading'] {
        margin-bottom: 0;
      }
      > * {
        padding: 12px 0;
      }
    }
    @media #{$mxw-xs} {
      .body {
        font-size: 14px;
      }
    }
    @media #{$mxw-xxs} {
      grid-template-columns: 80px 25px 80px 15px 90px auto;
      .body,
      * {
        font-size: 12px;
      }
    }
  } // .grid-row
} // .section-3

.accordion {
  .qa-wrap {
    border-bottom: 1px solid $gray;
    padding: 16px 0;
    &:first-of-type {
      border-top: 1px solid $gray;
    }
  }
  .q-wrap,
  .a-wrap {
    margin-left: 40px;
    @media #{$mxw-md} {
      margin-left: 5px;
    }
  }
  .trigger {
    display: flex;
    align-items: center;
  }
  .arrow {
    width: $arrow-w;
    height: $arrow-w;
    margin-right: 15px;
    transform: rotate(90deg);
    @include transition;
  }
  .q {
    font-family: $font-aldine-light;
    color: $black;
  }
  .a {
    font-size: 16px;
    margin-top: 10px;
    margin-left: $arrow-w + 15;
  }
  ul {
    font-size: 16px;
    margin-top: 10px;
    list-style: disc;
    margin-left: 47px;
    li {
      padding-bottom: 6px;
      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
  ul.decimal {
    list-style: decimal;
  }
  .collapsed {
    .arrow {
      transform: rotate(0deg);
    }
  }
} // .accordion

#terms-page {
  li {
    padding-bottom: 8px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
}
